.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.3s;
    z-index: 999;

}

.modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
}

.modal-content {
    position: relative;
    margin: 20px;
    width: 100%;
    max-width: 1000px;
    border-radius: 20px;
    background-color: white;
    padding: 40px 20px 20px 20px;
    transform: translateY(-50px);
    transition: opacity 0.3s, transform 0.3s;
}

.modal-close-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 16px;
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: transparent;
}

/* Open */
.modal--entering,
.modal--entered {
    opacity: 1;
}

.modal--entering .modal-content,
.modal--entered .modal-content {
    transform: translateY(0);
}

/* Close */
.modal--exiting,
.modal--exited {
    opacity: 0;
}

.modal--exiting .modal-content,
.modal--exited .modal-content{
    transform: translateY(-50px);
}
