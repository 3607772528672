.skidki{
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 1200px;
    margin: 0px auto;
    overflow: hidden;
}

.skidki_pozicii {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: center;
    border-right: 1px solid #ccc;
}

.skidki_stoimost {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: center;
}

.CartTableContainer {
    /* border-radius: 20px; */
    overflow: hidden; /* Ограничиваем видимую область, чтобы скролл не выходил за пределы границ */
    display: block;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 20px; /* Добавляем скругление краев таблицы */
    /* border: 1px solid black; */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 1200px;
    margin: 0px auto;
}

.CartTable {
    display: block;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    border-collapse: collapse;
    
    width: fit-content;
    overflow: auto;
}

.CartTable th,
.CartTable td {
    padding: 8px;
    border: none; /* Убираем границы у ячеек */
    text-align: center;
    /* padding: 10px; */
    width: auto;
    flex-wrap: wrap;
}

/* Границы только для внутренних ячеек */
.CartTable th, .CartTable td {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc; /* Добавляем правую границу для разделения столбцов */
    padding: 15px;
    flex-wrap: wrap;
    
}



.total-price-column {
    position: sticky;
    right: 0;
    background-color: #fff;  
    z-index: auto;
    min-width: 100px; /* Минимальная ширина колонки */
}

/* Применяем position: sticky; только к последней колонке */
.CartTable th:last-child .total-price-column,
.CartTable td:last-child .total-price-column {
    position: sticky;
    right: 0;
    background-color: #fff;
}

.CartTable th:last-child,
.CartTable td:last-child {
    background-color: #F1EBDD; /* Устанавливаем цвет фона для последней колонки */
}

/* Убираем правую границу у последней ячейки в строке */
.CartTable th:last-child, .CartTable td:last-child {
    border-right: none;
    
}

.CartTable tr:first-child th,
.CartTable tr:first-child td {
    border-bottom: 1px solid #ccc; /* Добавляем границу снизу для первой строки */
    
}

.add_button {
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    /* position: absolute; */
    color: #50651D;
    background-color: transparent;
    /* align-items: center; */
}


.add_button:active {
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    /* position: absolute; */
    color: #50651D;
    background-color: transparent;
    /* align-items: center; */
}

@media (hover: hover) {
    .add_button:hover {
        color: #98A446;
    }
}

@media (hover: none) {
    .add_button:active {
        color: #98A446;
    }
}

.remove_button {
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    /* position: absolute; */
    color: #50651D;
    background-color: transparent;
    /* align-items: center; */
}

.remove_button:active {
    display: flex;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    /* position: absolute; */
    color: #50651D;
    background-color: transparent;
    /* align-items: center; */
}

@media (hover: hover) {
    .remove_button:hover {
        color: #98A446;
    }
}

@media (hover: none) {
    .remove_button:active {
        color: #98A446;
    }
}

/* style={{ width: '440px', height: '320px', position: 'relative' }} */
.Card-per-product {
    width: 440px;
    height: 320px;
    position: relative;
}

/* style={{  display: 'flex', alignItems: 'center', justifyContent: 'center', height:'100%', margin:'20px 10px' }} */
.Content-per-product {
    display: flex;
    /* align-items:flex-start; */
    justify-content: center;
    width: 90%;
    margin: 0px auto;
    height: 90%;
    margin-top: 10px;
}

/* style={{ position: 'absolute', bottom: '20px', right:'20px', width: '100%', textAlign: 'right', cursor: 'pointer' }} */
.ButAdd-per-product {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 100%;
    text-align: right;
    cursor: pointer;
}

/* .media-query-style {
    border-radius: 20px 20px 20px 20px;
    background-size: auto;
    width: 200px;
    margin: 10px;
    margin-bottom: 60px;
    justify-self: center;
    align-self: center;
    align-items: center;
} */

.Text-body-per-product {
    height: 80%;
    width: 80%;
    margin: 0px 0px auto auto;
}

@media screen and (max-width: 760px) {

    .Text-body-per-product {
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        margin: 0px auto;
    }

    .media-query-style {
        display: flex;
        width: max-content;
        height: auto;
        overflow: hidden;
    }

    .Content-per-product {
        display: flex;
        /* flex: 0 0 auto; */
        flex-direction: column;
        top: 0;
        align-items: center;
        justify-content: space-between;
        height: 410px;
        width: 100%;
        margin: 0;
    }

    .Card-per-product {
        width: 220px;
        height: 500px;
        position: relative;
    }

    .ButAdd-per-product {
        display: flex;
        position: absolute;
        left: 44px;
        bottom: 20px;
        width: 100%;
        text-align: center;
        cursor: pointer;
    }

    .skidki{
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
        max-width: 1200px;
        margin: 0px auto;
        overflow: hidden;
    }

    .skidki_pozicii {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #ccc;
    }
    
    .skidki_stoimost {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
    }
}