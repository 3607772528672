.descriptionnn {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3; /* Указание количества строк, которые вы хотите отобразить */
  }
  /* style={{ marginTop: '-10px', height: 'calc(100% - 240px)', overflow: 'auto' }} */
  .TextNew {
    margin: 10px 16px 0px;
  }

  /* style={{ width: '350px', height: '400px', position: 'relative' }} */
  .NewCard {
    display: flex;
    width: 350px;
    height: 400px;
    position: relative;
  }

  .PhotoNew {
    height: 200px;
  }

  @media screen and (max-width: 760px) {
    .NewCard {
      width: 240px;
      height: 300px;
    }

    .PhotoNew {
      height: 120px;
    }
    .TextNew {
      display: flex;
      margin: 10px 10px 0px;
      /* margin-top: 10px; */
      height: 120px;
      flex-direction: column;
      justify-content: space-between;
    }

    .descriptionnn {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1; /* Указание количества строк, которые вы хотите отобразить */
    }

  }

  @media screen and (max-width: 1000px) {
    .grid-container {
      flex-direction: column;
    }
  
    .grid-item {
      width: 100%;
    }
  }
