.base {
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 auto;
    padding: 40px 0;
    
}

.text {
    text-align: right;
    margin-left: 80px;
}

.podval {
    margin-top: 120px;
    background-color: #F1EBDD;
    width: 100%;
}

@media screen and (max-width: 760px) {
    .base {
        display: flex;
        flex-direction: column;
    }

    .text {
        text-align: center;
        margin: 0;
        margin-top: 40px;
        word-wrap: normal;
    }

    .logo {
        display: flex;
        margin: 0;
        margin-left: 30px;
    }
}
