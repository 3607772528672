@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.button {
    font-family: 'Merri weather';
    background-color: #50651D; 
    border: 2px solid #50651D;
    color: white;
    padding: 8px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 4px 2px;
    transition-duration: 0.4s; 
    cursor: pointer;
    border-radius: 20px;
  }
  
  .button:hover {
    background-color: white; 
    border: 2px solid #50651D;
    color: #50651D;
    border-radius: 20px;
  }
  
  .button:active {
    font-family: 'Merri weather';
    background-color: #50651D; 
    border: 2px solid #50651D;
    color: white;
    padding: 8px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 4px 2px;
    transition-duration: 0.4s; 
    cursor: pointer;
    border-radius: 20px;
  }
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
  .button2 {
    font-family: 'Open Sans';
    color:rgb(155, 154, 154);
    font-size: 16px;
    transition-duration: 0.4s; 
  }

  .button2:hover {
    font-family: 'Open Sans';
    color:black;
    font-size: 16px;
  }

  .button2:active {
    font-family: 'Open Sans';
    color:black;
    font-size: 16px;
  }