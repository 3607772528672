@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  }

  header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 80%;
    z-index: 1000;
    margin: 0px auto;
  }
  
  .navbar {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 24px;
    position: relative;
    width: 100%;
    margin: auto;
    border-radius: 0px 0px 20px 20px;
  }

.navbar::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 96%;
  height: 2px; 
  background-color: #98A446;
  transform: translateX(-50%);
}

.logo {
  display: flex;
  align-items: center;
  margin-right: 32px;
}



.logo img {
  width: 40px;
  height: 40px;
}

.logo span {
  font-family: 'Merriweather';
  color: #50651D;
  font-size: 20px;
  margin-left: 10px;
  word-wrap: break-word;
  max-width: 150px;
}

/* style={{ width: '100%', height: 'auto', objectFit: 'cover'}}  */
 /* .img.mainPicHeader {
  width: 100%;
  height: 100%;
  object-fit: cover;
 } */


/* style={{ position: 'absolute', bottom: '0px', left: '50%', transform: 'translateX(-50%)', textAlign: 'center', color: 'white'}} */
.SHIK {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 50%;
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
}

  .logoH {
    display: flex;
    align-items: center;
    margin-right: 32px;
  }

  

  .logoH img {
    width: 40px;
    height: 40px;
  }
  
  .logoH span {
    font-family: 'Merriweather';
    color: #50651D;
    font-size: 20px;
    margin-left: 10px;
    word-wrap: break-word;
    max-width: 150px;
  }

  .nav-links {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    margin: 0 0px;
    width: fit-content;
    padding: 0;
    z-index: 2;
    flex-direction: row;
  }
  
  .nav-links li {
    padding: 10px 0 0 0 ;
    align-items: center;
    justify-content: center;
    display: inline-block;
    margin-right: 50px;
  }
  

  
  .nav-links li a {
    font-family: 'Merriweather';
    align-items: center;
    justify-content: center;
    color: #50651D;
    text-decoration: none;
    font-size: 16px;
    position: relative;
  }

  .nav-links li a::after {
    content: '';
    position: absolute;
    bottom: -18px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #50651D;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
    z-index: 2;
    visibility: visible;
}

  .nav-links li a:hover::after {
    visibility: visible;
    transform: scaleX(1.5);
  }

  main img {
    height: auto;
    width: 100%;
    object-fit: cover;
    margin: 0px auto;
  }

  .header_menu-button {
    display: none;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    
  }

  .header_menu-button svg {
    fill: #50651D;
  }

  .header_links {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }

  .social-links{
    display: flex;
    padding: 10px 0 0 0;
  }

  .social-links a img{
    width: 32px;
    height: 32px;
    transition: transform 0.3s;
    margin: 0px 16px 0px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Начальный прозрачный фон */
    z-index: 9; /* Располагаем ниже меню, чтобы не перекрывать его */
    transition: background-color 0.3s ease; /* Плавное изменение цвета фона */
    pointer-events: none; /* Позволяет событиям проходить через элемент */
  }
  
  .overlay.active {
    background-color: rgba(0, 0, 0, 0.5); /* Затемненный фон */
  }

  @media screen and (max-width: 1100px) {

    header {
      display: flex;
      position: absolute;
      top: 0;
      width: 80%;
      margin: 0px auto;
    }

    .header_menu-button {
      display: block;
    }

    .nav-links.active {
      transform: translateY(100%);
      transition: opacity 0.3s, transform 0.5s;
    }

    .navbar {
      display: flex;
      justify-content: space-between;
    }

    .social-links{
      display: flex;
      padding: 10px 0;
    }

    .nav-links {
      padding-top: 10px;
      position: absolute;
      top: -424px;
      right: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #fff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      transition: opacity 0.3s, transform 0.3s;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      z-index: 10;
    }

    .nav-links li {
      padding: 10px;
      margin: 0;
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      left: 0;
    }

    .logoH {
      display: flex;
      left: 0;
    }

    .nav-links li a::after {
      top: 120%;
      left: 50%;
      width: 100%;
      height: 2px; 
      background-color: #98A446;
      transform: translateX(-50%);
    }
    
    .nav-links li a:hover::after {
      top: 120%;
      left: 50%;
      width: 100%;
      height: 2px; 
      background-color: #50651D;
      transform: translateX(-50%);
    }

    .navbar::after {
        display: block;
        width: 94%;
    }

  }

  .Assortiment {
    width: 80%;
  }


.card {
  flex-wrap: wrap; 
  display: flex;
  text-align: center;
  width: 80%;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px auto;
 }
 .cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  margin-bottom: 40px;
  padding: 10px;
 }

.image {
  display: flex;
  width: 100px;
  height: auto;
 }

.cardsAssortiment {
  display: flex;
  text-align: center;
  margin-top: 80px;
  width: 80%;
  margin: 0px auto;
  justify-content: space-between;
}

.CardAssortiment {
  display: flex;
  width: 30%;
  height: auto;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.TBAssortiment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.CallOutlinedIcon {
  width: 24px;
  height: 24px;
}

.CardAboutUSContainer {
  background-color: #F1EBDD;
  margin-top: 40px;
  padding-top: 10px;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  height: auto;
}

.CardAboutUSContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height:fit-content;
  align-items: flex-end;
  margin-top: -30px;
}

.CardAboutUSText {
  width: 100%;
  padding-left: 5%;
  flex: 6;
  justify-content: center;
  padding-bottom: 20px;
}

.CardAboutUS {
  background-color: white;
  padding: 2px 20px 20px;
  margin: 16px 0;
  border-radius: 20px;
  width: 100%;
  align-items: center;
}

.CardAboutUSImage {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height:auto;
  flex: 4;
}

.Contacts {
  position:absolute;
  display: flex;
}

.news-feed {
  padding: 20px;
}

.news-item {
  width: 200px; /* Задайте ширину ваших новостей */
  margin-right: 20px; /* Отступ между новостями */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

/* style={{width:'100%', marginRight:'20%', marginTop:'5%'}} */
.myForm {
  justify-content: center;
  width: 100%;
  margin-right: 10%;
  margin-top: 5%;
}

/* style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width:'80%', margin: '0 auto'}} */
.myFormPhoto {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0px auto; 
}

.PicMyForm {
  display: flex;
  width: 60%;
  margin-bottom: 40px;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.bodyyy {
  white-space: wrap;
  margin-bottom: 5px;
}

.bodyyy2 {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 16px;
  white-space: wrap;
}


.ModalTextFeedback {
  display: flex;
  margin-top: -30px;
  flex-direction: column;
}


@media screen and (max-width: 1000px) {

  .SHIK {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 80%;
    background-color: #fff;
    border-radius: 20px 20px 0px 0px;
  }

  main img {
    height: 300px;
    width: 100%;
    object-fit: cover;
  }

  .card {
   flex-wrap: wrap; 
   display: flex;
   text-align: center;
   width: 100%;
   flex-direction: row;
   justify-content: space-between;
   margin: 0px auto;
  }
  .cards {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 46%;
   margin-bottom: 40px;
   padding: 10px;
  }

  .TBAssortiment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0px 20px;
    flex-direction: column;
  }

  .CardAboutUSContent {
    display: flex;
    flex-direction: row;
    height:fit-content;
    width: 80%;
    margin: 0px auto;
    height: fit-content;
  }

  .CardAboutUSContainer {
    background-color: #F1EBDD;
    margin-top: 40px;
    padding-top: 10px;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    height: auto;
  }
  
  .CardAboutUSText {
    width: 100%;
    padding-left: 0%;
  }
  
  .CardAboutUS {
    background-color: white;
    border-radius: 20px;
    width: fit-content;
    /* width: 100%; */
    align-items: center;
    /* margin: 20px auto; */
  }
  
  .CardAboutUSImage {
    position: absolute;
    left:0;
    right: 0;
    z-index: -1;
  }

  .myForm {
    display: flex;
    width: 100%;
    margin-top: 5%;
    margin: 0px auto;
    flex-direction: column;
  }
  
  .myFormPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0px auto; 
    flex-direction: column;
  }

  .PicMyForm {
    width: min-content;
    position: absolute;
    left:0;
    right: 0;
    z-index: -1;
  }
  
  .ButtonMyForm {
    display: flex;
    right: 0;
    justify-content: right;
  }


 }
 
 @media screen and (max-width: 760px) {

  .SHIK {
    word-wrap: normal;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    background-color: #fff;
    border-radius: 20px 20px 0px 0px;
    border-bottom: 2px solid #50651D;
    
  }

  main img {
    height: 400px;
    width: auto;
    object-fit: cover;
  }

   .card {
    flex-wrap: wrap; 
    display: flex;
    text-align: center;
    width: 80%;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px auto;
   }
   .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
    padding: 10px;
   }
   .cardsAssortiment {
    flex-direction: column;
    align-items: center;
  }
  
  .CardAssortiment {
    margin-bottom: 40px;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
  }
  
  .TBAssortiment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    flex-direction: row;
  }

  .navbar::after {
    display: block;
    width: 92%;
}

 }
 