.MainContainer1 {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    align-items: stretch;
    justify-content: center;
}

.ServicesBlock1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    
}

.Text1 {
    /* background-color: #F0E8E3; */
    background-color: white;
    border-radius: 20px;
    width: 60%;
    align-items: center;
    padding: 10px;
}

.ServicesBlock2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

}

.Text2 {
    width: 60%;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    padding: 10px;
}

.picture1 {
    display: flex;
    height: 400px;
    width: auto;
    margin-right: 32px;
}

.picture2 {
    display: flex;
    height: 400px;
    width: auto;
    margin-left: 32px;
}

@media screen and (max-width: 1000px) {

    .MainContainer1 {
        display: flex;
        flex-direction: column;
        width: 80%;
        max-width: 1200px;
        margin: 0 auto;
    }

    .ServicesBlock1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* align-items: center; */
        justify-content: center;
        margin: 0px auto;
        border-bottom: 2px #50651D;
    }

    .Text1::after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: #50651D;
    }

    .picture1 {
        display: flex;
        height: 50%;
        width: auto;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .picture1 img {
        order: 1;
        display: flex;
        position: relative;
        width: 100%;
        height: auto;
        margin: 0px auto;
    }

    .picture2 {
        display: flex;
        height: 50%;
        width: auto;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .picture2 img {
        order: 1;
        display: flex;
        position: relative;
        width: 100%;
        height: auto;
        margin: 0px auto;
    }

    .Text1 {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0;
    }

    .ServicesBlock2 {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }

    .Text2::after {
        content: '';
        width: 100%;
        height: 2px;
        background-color: #50651D;
    }

    .Text2 {
        order: 2;
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0;
    }

}