@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.ContainerAboutUsPageAboutUs1{
    display: flex;
    background-color: #F1EBDD;
    margin: 60px auto;
    padding-top: 40px;
    padding-bottom: 40px;
    /* position: absolute; */
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.AboutUsSvg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.TextAboutUs1 {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 80%;
}

.TextAboutUs1 {
    display: flex;
    flex-direction: column;
    margin-top: -15px;
}


.cards_history_aboutus {
    display: flex;
    flex-direction: row;
    width: fit-content;
    max-width: 46%;
    margin-bottom: 40px;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
    border-radius: 20px;
    /* border: 1px solid #D9D9D9; */
    padding: 10px;
}

.container_history {
    flex-wrap: wrap; 
    margin-bottom: -20px;
    display: flex;
    text-align: left;
    width: 80%;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px auto;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
}

.TextAboutUsMainPic {
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin: 0px auto;
    flex-direction: row;
    /* position: absolute; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.TextAboutUsPic {
    display: flex;
    flex-direction: column;
    width: 30%;
    background-color: #fff;
    padding: 0px 20px;
    border-radius: 20px;
    margin: 20px 0;
    justify-content: center;
    height: auto;
}
/* .TextAboutUsPic:first-child {
    border-radius: 0px 0px 20px 20px;
}
.TextAboutUsPic:last-child {
    border-radius: 20px 20px 0px 0px;
} */

.HistoryText1 {
    margin-left: 32px;
}

@media screen and (max-width: 768px) { /* Применяем стили для экранов шириной до 768px */
    .container_history {
        flex-direction: column; /* Изменяем направление на вертикальное */
        align-items: center; /* Выравниваем элементы по центру */
        width: 80%; /* Занимаем всю доступную ширину */
    }
    .cards_history_aboutus {
        display: flex;
        flex-direction: row;
        width: fit-content;
        max-width: 100%;
        margin-bottom: 32px;
    }

    .HistoryText1 {
        margin-left: 20px;
        width: auto;
    }
}

